import React from "react";
import { useCanvasMap } from "./useCanvasMap";
import { IMap } from "../Map/map.interface";

export const CanvasMap: React.FC<IMap> = ({
                                            fields,
                                            onClick,
                                            boardSize,
                                            selectionArea,
                                            setLoading
                                          }) => {
  const { ref } = useCanvasMap({ fields, onClick, selectionArea, boardSize, setLoading });

  return (
    <div className="map" ref={ref} />
  );
};
