import React from "react";
import { IMap } from "./map.interface";
import { CanvasMap } from "../CanvasMap";

export const Map: React.FC<IMap> = ({fields, onClick, boardSize, selectionArea, setLoading}) => {

  return <CanvasMap
    setLoading={setLoading}
    selectionArea={selectionArea}
    boardSize={boardSize}
    onClick={onClick}
    fields={fields}
  />
}
