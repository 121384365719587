import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Application,
  Container,
  Graphics,
  InteractionEvent,
  Renderer,
  SCALE_MODES,
  settings,
  Sprite,
  Texture
} from "pixi.js";
import { Assets } from "@pixi/assets";
// import { settings } from "@pixi/settings";
import { IMap } from "../Map/map.interface";
import { Viewport } from "pixi-viewport";

const lastPos = { x: 0, y: 0 };

export const useCanvasMap = ({ fields, onClick, boardSize, selectionArea, setLoading }: IMap) => {

  const ref = useRef<HTMLDivElement>(null);
  const app = useMemo(() => (
    new Application({
      antialias: false,
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundAlpha: 0,
      autoDensity: true,
      autoStart: true,
      resizeTo: window,
      resolution: 2
    })
  ), []);
  const renderer = useMemo(() => new Renderer(), []);

  const [baseSprites, setBaseSprites] = useState<any>({});


  // settings.RESOLUTION = 2;
  settings.SCALE_MODE = SCALE_MODES.NEAREST;
  const assets = useMemo(() => Assets, []);
  const graphics = useMemo(() => new Graphics(), []);
  const maskGraphics = useMemo(() => new Graphics(), []);
  const particle = useMemo(() => new Container(), []);
  const shapesContainer = useMemo(() => new Container(), []);
  const container = useMemo(() => new Container(), []);
  const stageContainer = useMemo(() => new Container(), []);
  const dragContainer = useMemo(() => new Container(), []);
  const selectRectangle = useMemo(() => new Graphics(), []);
  const viewport = useMemo(() => new Viewport({
    // boardSize * fieldSize
    worldWidth: 1000,
    worldHeight: 1000,
    passiveWheel: false,
    interaction: app.renderer.plugins.interaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [app, boardSize]);
  // const [windowWidth, windowHeight] = useWindowSize();

  const onPointerDown = (e: InteractionEvent) => {
    const event = e.data.originalEvent as PointerEvent;
    lastPos.x = event.clientX;
    lastPos.y = event.clientY;
    if (e.data.pointerType === "touch") {
      const touchEvent = e.data.originalEvent as any;
      lastPos.x = touchEvent.touches.item(0)?.clientX ?? 0;
      lastPos.y = touchEvent.touches.item(0)?.clientY ?? 0;
    }
  };

  const onPointerUp = useCallback((e: InteractionEvent) => {
    const event = e.data.originalEvent as PointerEvent;
    if (e.data.pointerType === "touch") {
      const touchEvent = e.data.originalEvent as TouchEvent;
      if (
        touchEvent.changedTouches.length > 1
        || e.data.global.x !== lastPos.x
        || e.data.global.y !== lastPos.y
      ) {
        return;
      }
    } else if (e.data.pointerType === "mouse" && (lastPos.x !== event.clientX || lastPos.y !== event.clientY)) {
      return;
    }
    selectRectangle.clear();
    const coordinates = e.data.getLocalPosition(dragContainer);
    const x = Math.floor(coordinates.x);
    const y = Math.floor(coordinates.y);
    const fieldX = x < 50 ? 0 : Math.floor(x / 50) * 50;
    const fieldY = y < 50 ? 0 : Math.floor(y / 50) * 50;
    const cellX = x < 5 ? 0 : Math.floor(x / 5) * 5;
    const cellY = y < 5 ? 0 : Math.floor(y / 5) * 5;
    const field = fields.find(el => el.x === fieldX && el.y === fieldY);
    const cell = field?.cells.find(cell => cell.x === cellX && cell.y === cellY);
    if (field && cell) {
      const selectedColor = cell.color.includes("000000") ? "ffffff" : "000000";
      selectRectangle.beginFill(parseInt(selectedColor, 16), 0);
      selectRectangle.lineStyle(2, parseInt(selectedColor, 16), 1);
      if (selectionArea === "cell") {
        selectRectangle.drawRect(cellX, cellY, 5, 5);
      } else {
        selectRectangle.drawRect(fieldX, fieldY, 50, 50);
      }
      dragContainer.addChild(selectRectangle);
      if (onClick) onClick(field, cell);
    }
  }, [dragContainer, fields, onClick, selectRectangle]);

  const initCanvas = () => {
    if (ref.current === null || boardSize === 0) return;
    const worldSize = boardSize * 50;
    viewport.worldWidth = worldSize;
    viewport.worldHeight = worldSize;
    viewport
      .drag()
      .decelerate()
      .wheel()
      .fit()
      .pinch()
      .clamp({ direction: "all" })
      .clampZoom({ minScale: viewport.scaled, maxScale: 2 });

    ref.current.appendChild(app.view);
    app.start();
    return () => app.stop();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initCanvas, [boardSize]);

  // const resizeCanvas = () => {
  //   viewport.resize(windowWidth, windowHeight);
  //   viewport.fit().clampZoom({ minScale: viewport.scaled, maxScale: 20 });
  // };

  // useEffect(resizeCanvas, [windowWidth, windowHeight, app, viewport]);

  const renderMap = () => {
    (async () => {
      if (boardSize === 0) {
        return;
      }
      if (fields.length === (boardSize * boardSize)) {
        container.removeChildren();
        dragContainer.removeChildren();
        stageContainer.removeChildren();
        particle.removeChildren();
        shapesContainer.removeChildren();
        app.stage.removeChildren();
      }
      const promises = fields.map(async (field, i) => {

        field.cells.forEach((cell) => {
          const sprite = new Sprite(Texture.WHITE);
          sprite.width = 5;
          sprite.height = 5;
          sprite.x = cell.x;
          sprite.y = cell.y;
          sprite.tint = parseInt(cell.color, 16);
          container.addChild(sprite);
        });

        if (field.shape) {
          const texture = await assets.load(field.shape.url);
          // const sprite = Sprite.from(field.shape.url)
          const sprite = new Sprite(texture);
          sprite.name = `${field.x}x${field.y}`;
          sprite.width = 50;
          sprite.height = 50;
          sprite.x = field.x;
          sprite.y = field.y;
          sprite.tint = parseInt(field.shape.color, 16);
          shapesContainer.addChild(sprite);
        }
      });
      await Promise.all(promises);
      const texture = app.renderer.generateTexture(container);
      const sprite = new Sprite(texture);
      particle.interactive = true;
      particle.buttonMode = true;

      dragContainer.removeChildren();
      stageContainer.removeChildren();
      particle.removeChildren();
      particle.addChild(sprite, shapesContainer);

      dragContainer.addChild(particle);
      dragContainer.addChild(selectRectangle);
      stageContainer.addChild(dragContainer);
      // @ts-ignore
      viewport.addChild(stageContainer);
      // @ts-ignore
      app.stage.addChild(viewport);
      if (onClick) {
        particle.on("pointerup", onPointerUp);
        particle.on("pointerdown", onPointerDown);
      }
      setLoading(false);
    })();

    return () => {
      particle.off("pointerup", onPointerUp);
      particle.off("pointerdown", onPointerDown);
    };
  };

  useEffect(renderMap, [
    app.renderer,
    app.stage,
    boardSize,
    container,
    dragContainer,
    fields,
    graphics,
    shapesContainer,
    particle,
    selectRectangle,
    stageContainer,
    viewport,
    baseSprites
  ]);

  return {
    ref
  };
};
