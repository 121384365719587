import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./services/i18n";
import { Router } from "./routes/router";

const ErrorBoundary = lazy(() => import("./components/ErrorBoundary")
  .then(module => ({ default: module.ErrorBoundary })));

function App() {

  return (
    <Suspense>
      <ErrorBoundary>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
