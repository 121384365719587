import React, { FC, useEffect, useRef } from "react";
import { gsap } from "gsap";

interface IAccordionProps {
  children: React.ReactNode;
}

export const Accordion: FC<IAccordionProps> = ({children}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.set( '.accordion-item__content' , { height: 0 } );
  }, [])

  return <div ref={ref} className="accordion">{children}</div>
}
