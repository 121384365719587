import { Link } from "../Link";
import { FC, useEffect, useRef } from "react";
import { IMagneticButtonProps } from "./magnetic-button.interface";
import ButtonCtrl from "./buttonCtrl";

export const MagneticButton: FC<IMagneticButtonProps> = ({ children, to }) => {
  const buttonRef = useRef<HTMLElement>(null);
  const event = useRef<any>(null);

  const windowSizeHandler = () => {
    if (window.innerWidth < 400 || !buttonRef.current) {
      return;
    }
    event.current = new ButtonCtrl(buttonRef.current);

    return () => {
      event.current = null;
    };
  };

  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }

    windowSizeHandler();

    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return <Link ref={buttonRef} className="button button--mimas button-action" to={to}>
    <span className="button__text">
      <span className="button__text-inner">{children}</span>
    </span>
  </Link>;
};
