import './Loading.scss'

export const Loading = () => (
  <div className="loading">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)