export const ArrowRight = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_78_3)">
      <path d="M18 6L16.57 7.393L24.15 15H4V17H24.15L16.57 24.573L18 26L28 16L18 6Z" />
    </g>
    <defs>
      <clipPath id="clip0_78_3">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
  </svg>
);
