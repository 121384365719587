import { useEffect, useState } from "react";
import { ICell, IField } from "./board.interface";
import { io, Socket } from "socket.io-client";

export const useBoard = () => {
  const [boardSize, setBoardSize] = useState(0);
  const [selectedCell] = useState<ICell>();
  const [fields, setFields] = useState<IField[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [socket, setSocket] = useState<Socket | null>();

  const initBoard = () => {
    setLoading(true);
    setSocket(io());

    fetch("/api", {
      headers: {
        "Content-type": "application/json"
      }
    })
      .then(response => response.json())
      .then((result: { boardSize: number, fields: IField[] } | { error: string; }) => {
        if ("error" in result) return;
        setBoardSize(result.boardSize);
        setFields(result.fields);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      })
  };

  useEffect(initBoard, []);

  const initSocket = () => {
    if (!socket) {
      return;
    }

    socket.on("fields", (msg: IField[]) => {
      setFields((prevState => (prevState.map(field => {
        const updated = msg.find(item => item.column === field.column && item.row === field.row);
        if (updated) {
          return {
            ...field,
            ...updated
          };
        }
        return field;
      }))));
    });

    socket.on("connect_error", (error) => {
      console.log(error);
    });

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
  };

  useEffect(initSocket, [socket]);

  return {
    boardSize,
    selectedCell,
    fields,
    isLoading,
    setLoading
  };
};
