import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Link } from "../components/Link";
import { defaultLocale, supportedLocales } from "../services/locales";
import "../styles/main-layout.scss";
import { MagneticButton } from "../components";

interface IMainLayoutProps {
  children: JSX.Element;
}

export const MainLayout: React.FC<IMainLayoutProps> = ({ children }) => {
  const { t } = useTranslation("main-layout");
  const location = useLocation();
  const navigate = useNavigate();
  const changeLanguage = (lng: string) => {
    const path = location.pathname.split("/").filter(Boolean);
    const localeIndex = path.indexOf(i18n.language);
    if (lng === defaultLocale) {
      path.splice(0, 1);
    } else {
      if(localeIndex === 0) {
        path.splice(localeIndex, 1, lng);
      } else {
        path.splice(0, 0 , lng)
      }
    }
    path.splice(0, 0, '')
    i18n.changeLanguage(lng);
    navigate(path.join("/"));
  };

  useEffect(() => {
    const path = location.pathname.split('/').filter(Boolean);
    if(supportedLocales.includes(path[0])) {
      i18n.changeLanguage(path[0]);
    } else {
      i18n.changeLanguage(defaultLocale);
      if(path.length > 1 && path[0].length === 2) {
        path.splice(0, 1)
        navigate(path.join('/'))
      }
    }
  }, [location, navigate])

  return (
    <div>
      <header>
        <div className="header-container">
          <div className="header-logo">
            <Link to="/">
              <img src="/assets/img/logo.svg" />
            </Link>
          </div>
        </div>
      </header>
      <main>{children}</main>
      <div className="lead-container">
        <div className="action-button">
          <MagneticButton to="/sandbox">Try sandbox</MagneticButton>
        </div>
      </div>
    </div>
  );
};
