import { useEffect, useState } from "react";
import { ICell, IField } from "./board.interface";
import { button, folder, useControls } from "leva";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

let abortController: AbortController | null = null;

let prevFillBoard = false;
let prevBoardSize = 0;

export const useBoard = () => {

  const [shapes, setShapes] = useState<string[]>([]);
  const [formulasList, setFormulasList] = useState<string[]>([]);

  const [selectedCell, setSelectedCell] = useState<ICell>();
  const [selectedField, setSelectedField] = useState<IField>();
  const [fields, setFields] = useState<IField[]>([]);
  const [boardSize, setBoardSize] = useState(0);
  const [selectedExampleFile, setSelectedExampleFile] = useState("");
  const [selectedFieldImageUrl, setSelectedFieldImageUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation("sandbox");
  const location = useLocation();

  const [, set] = useControls(() => ({
    [t("field")]: folder({
      position: {
        label: t("position"),
        value: { x: 0, y: 0 },
        min: 0,
        step: 1,
        max: prevBoardSize - 1,
        joystick: false
      },
      color: {
        value: "",
        options: {
          "dark blue": "#00003D",
          "dark pink": "#A0508C",
          "light violet": "#8A8AD8",
          "black": "#000000",
          "dark orange": "#D88156",
          "dark red": "#B8001A",
          "purple": "#6100FF",
          "yellow": "#FFD600"
        }
      },
      formula: {
        label: t("formula"),
        value: "none",
        options: formulasList
      },
      exampleImage: {
        value: "none",
        label: t("shape"),
        options: ["none", ...shapes]
      },
      [t("save field")]: button((get) => {
        setLoading(true);
        const color = get("field.color");
        const position = get("field.position");
        const exampleImage = get("field.exampleImage");
        const formula = get("field.formula");
        fetch("/api/sandbox", {
          method: "PUT",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify({
            x: Math.round(position.x),
            y: Math.round(position.y),
            formula_id: formula,
            shape: exampleImage,
            color
          })
        })
          .then((response) => response.json())
          .then((result: IField[]) => {
            setLoading(false);
            setSelectedCell(result[0].cells[0]);
            // @ts-ignore
            setFields((prevState => (prevState.map(field => {
              const updated = result.find(item => item.column === field.column && item.row === field.row);
              if (updated) {
                return {
                  ...field,
                  ...updated
                };
              }
              return field;
            }))));
          });
      })
    })
  }), [shapes, formulasList, selectedField, location]);

  useEffect(() => {
    fetch("/api/formulas")
      .then(response => response.json())
      .then(result => {
        const formulasObject = ["none", ...result].reduce((acc: any, item: string) => {
          const itemName = item.includes("default") ? "1color" : item;
          if (!acc?.[itemName]) {
            acc[itemName] = "";
          }
          acc[itemName] = item;
          return acc;
        }, {});
        setFormulasList(formulasObject);
      });
  }, []);

  const initBoard = () => {
    setLoading(true);
    fetch("/api/sandbox", {
      headers: {
        "Content-type": "application/json"
      }
    })
      .then(response => response.json())
      .then((result: { boardSize: number, fields: IField[] } | { error: string; }) => {
        if ("error" in result) return;
        setSelectedField(result.fields[0]);
        set({
          // @ts-ignore
          formula: result.fields[0].formula_id
        });
        setBoardSize(result.boardSize);
        prevBoardSize = result.boardSize;
        setSelectedFieldImageUrl(result?.fields[0]?.image?.high_quality ?? "");
        setFields(result.fields);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  // eslint-disable-next-line
  useEffect(initBoard, []);

  const getShapes = () => {
    fetch("/api/assets")
      .then(response => response.json())
      .then(result => {
        setShapes(result);
      });
  };

  useEffect(getShapes, []);

  return {
    boardSize,
    selectedCell,
    selectedField,
    fields,
    selectedExampleFile,
    selectedFieldImageUrl,
    isLoading,
    setLoading,
    setSelectedCell,
    setSelectedField,
    setSelectedFieldImageUrl,
    setSelectedExampleFile,
    set
  };
};
