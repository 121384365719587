import { Route, Routes } from "react-router-dom";
import React, { lazy } from "react";
import { Layout } from "../components/Layout";
import { MainLayout } from "../layouts";
import { defaultLocale, supportedLocales } from "../services/locales";
import { BoardLayout } from "../layouts/BoardLayout";

const Home = lazy(() => import("../pages/Home")
  .then(module => ({ default: module.Home })));
const Sandbox = lazy(() => import("../pages/Sandbox")
  .then(module => ({ default: module.Sandbox })));
const NotFound = lazy(() => import("../pages/NotFound")
  .then(module => ({ default: module.NotFound })));

const routes = [
  {
    path: "/",
    element: Home
  },
];

const boardRoutes = [
  {
    path: "/sandbox",
    element: Sandbox
  }
];

export const Router = () => {

  return (
    <Routes>
      <Route element={<Layout layout={MainLayout} />}>
        {supportedLocales.map((lang: string) => (
          routes.map(({ element: Element, path }: any) => (
            <Route
              key={path}
              path={`${lang === defaultLocale ? "" : lang}${path}`}
              element={<Element />}
            />
          ))
        ))}
        <Route
          path="*"
          element={<NotFound />}
        />
      </Route>
      <Route element={<Layout layout={BoardLayout} />}>
        {supportedLocales.map((lang: string) => (
          boardRoutes.map(({ element: Element, path }: any) => (
            <Route
              key={path}
              path={`${lang === defaultLocale ? "" : lang}${path}`}
              element={<Element />}
            />
          ))
        ))}
        <Route
          path="*"
          element={<NotFound />}
        />
      </Route>
    </Routes>
  );
};
