import React, { FC, useRef } from "react";
import "./styles.scss";
import { gsap, Sine } from "gsap";
import { ArrowRight } from "../../libs/icons";

interface IAccordionItemProps {
  children: React.ReactNode;
  title: string;
}

export const AccordionItem: FC<IAccordionItemProps> = ({ title, children }) => {

  const ref = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const onToggleAccordion = () => {
    if (!ref.current || !arrowRef.current) {
      return;
    }
    const parent = ref.current.closest(".accordion-item");
    const accordionContent = parent?.querySelector(".accordion-item__content");
    if (!accordionContent) {
      return;
    }

    if (parent?.classList.contains("is-open")) {
      gsap.to(arrowRef.current, .2, {rotate: 0})
      gsap.to(accordionContent, .2, { height: 0, ease: Sine.easeOut });
      parent?.classList.remove("is-open");
    } else {
      gsap.to(arrowRef.current, .2,  {rotate: 90, ease: Sine.easeIn})
      gsap.set(accordionContent, { height: "auto" });
      gsap.from(accordionContent, .2, { height: 0, ease: Sine.easeOut });
      parent?.classList.add("is-open");
    }
  };

  return (
    <div className="accordion-item">
      <div className="accordion-item__title" onClick={onToggleAccordion} ref={ref}>
        <div ref={arrowRef}>
          <ArrowRight />
        </div>
        {title}
      </div>
      <div className="accordion-item__content">
        <div className="accordion-item__content-container">
          {children}
        </div>
      </div>
    </div>
  );
};
