import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ILinkProps } from "./link.interface";
import { defaultLocale } from "../../services/locales";
import i18n from "i18next";

export const Link: React.FC<ILinkProps> = forwardRef(({ className, to, children }, ref) => {
  const locale = defaultLocale === i18n.language ? "" : i18n.language;
  const pathArray = to.split("/").filter(Boolean);
  pathArray.splice(0, 0, locale);
  return <RouterLink ref={ref} to={pathArray.join("/")} className={className}>{children}</RouterLink>;
});
