import React from "react";
import { Loading } from "../Loading";
import { Map } from "../Map";
import "./styles.scss";
import { useBoard } from "./useBoard";
import { Leva } from "leva";

export const BoardSandbox = () => {
  const {
    isLoading,
    setLoading,
    fields,
    setSelectedCell,
    setSelectedField,
    set,
    boardSize
  } = useBoard();

  return (
    <div className="board">
      <Leva hideCopyButton />
      {isLoading && <Loading />}
      <Map
        setLoading={setLoading}
        onClick={(field, cell) => {
          setSelectedCell(cell);
          setSelectedField(field);
          set({
            // @ts-ignore
            formula: field.formula_id,
            // @ts-ignore
            position: { x: field.row, y: field.column },
          });
        }}
        boardSize={boardSize}
        fields={fields}
        selectionArea="field"
      />
    </div>
  );
};
